let english = {
  logo_alt: "Cyrculus Logo",
  email: "Email",
  password: "Password",
  forgot_password: "Forgot password ?",
  sign_in_instead: "Don't have an account",
  please_wait: "Please wait...",
  something_went_wrong: "Something went wrong.",
  login_success: "You have successfully loged in.",
  login_to_access: "Please login to access this page.",
  terms_of_use: "Terms of use",
  my_account: "My Account",
  track_my_order: "Track my order",
  signin: "Sign in",
  signup: "Sign up",
  home: "Home",
  new_arrival: "New arrival",
  products: "Products",
  product: "Product",
  about_us: "About us",
  contact_us: "Contact us",
  my_wishlist: "My wishlist",
  wishlist: "Wishlist",
  cart: "Cart",
  search_products: "Search all products",
  naturally_delivered_title: "Naturally Delivered",
  naturally_delivered_description: "Natural & organic beauty products",
  secure_payments_title: "Secure Payments",
  secure_payments_description: "Fully protected when paying online",
  footer_logo_alt_text: "Logo",
  facebook: "Facebook",
  twitter: "Twitter",
  youtube: "Youtube",
  instagram: "Instagram",
  pinterest: "Pinterest",
  office_info: "OFFICE INFO",
  get_help: "GET HELP",
  faqs: "FAQs",
  business_with_us: "Business with us",
  shop_links: "SHOP LINKS",
  my_cart: "My cart",
  checkout: "Check out",
  privacy_policy: "Privacy policy",
  location_on_map: "Location on map",
  zipcode: "Zip",
  shop_now: "SHOP NOW",
  best_seller_text: "Best sellers of the week",
  best_seller_header: "For your honey",
  share: "Share",
  zoom: "Zoom",
  add_to_cart: "Add to cart",
  offers: "Offers",
  best_seller: "All Products",
  new_arrival_home: "Best Seller",
  offer_poster: "Offers poster",
  login_page_description:
    "Let’s get you all set up so you can verify your personal account and begin setting up your profile.",
  sign_in_image_alt_text: "Sign In",
  signup_page_description:
    "Let’s get you all set up so you can verify your personal account and begin setting up your profile.",
  sign_up_image_alt_text: "Sign In",
  dont_have_account: "Don't have an account?",
  remember_me: "Remember me",
  first_name: "First name",
  last_name: "Last name",
  confirm_password: "Confirm password",
  phone_number: "Phone number",
  already_have_account: "Already have an account?",
  create_account: "Create Account",
  i_agree: "I agree to all the",
  terms_and_policy: "Term, Privacy Policy",
  check_terms_and_condition: "Please accept our Terms & Privacy policy",
  password_mismatch: "Confirm password should be equal to password.",
  categories: "Categories",
  out_of_stock: "Out of stock",
  sort_by: "Sort By",
  newest: "Newest",
  oldest: "Oldest",
  mintomax: "Price Low to High",
  maxtomin: "Price High to Low",
  items: "Items",
  all_categories: "All Categories",
  filter_by_price: "Filter by Price",
  price: "Price",
  qty: "Qty",
  total: "Total",
  filter: "Filter",
  clear_filter: "Clear Filter",
  show: "Show",
  filter_by_ratings: "Filter by Rating",
  go_back: "GO BACK TO HOME",
  no_data_found: "No Data Found",
  products_detail: "Product Details",
  product_details_not_found: "Product details not found.",
  type: "Type",
  incredient: "Ingredient",
  reviews: "Reviews",
  in_stock: "In stock",
  weight: "Weight",
  reduce: "Reduce",
  increase: "Increase",
  add_to_wishlist: "Add to wishlist",
  remove_from_wishlist: "Remove from wishlist",
  compare: "Compare",
  delivery: "Delivery",
  delivery_description: "Speedy and reliable parcel delivery!",
  product_demand:
    "Other people want this. ##USERCOUNT## people have this in their carts right now.",
  related_products: "Related Products",
  description: "Description",
  review_for: "review for",
  no_reviews_found: "No reviews found",
  you: "You",
  add_a_review: "Add a review",
  required_field_info: "Required fields are marked with *",
  your_rating: "Your rating",
  review_title: "Review title",
  your_review: "Your review",
  submit: "Submit",
  contact_info: "Contact info",
  name: "Name",
  message: "Message",
  accept_terms_and_condition: "Accept Terms & Condition",
  please_accept_terms_and_condition: "Please accept terms and condition.",
  please_login: "To access this feature please login.",
  remove: "Remove",
  your_cart: "Your Cart",
  sub_total: "Sub Total",
  shipping_fee: "Fee Shipping",
  apply_promocode: "Apply Promo Code",
  apply: "Apply",
  enter_promocode: "Enter promo code",
  payment_process: "Confirm order",
  continue_shopping: "Continue Shopping",
  select_the_quantity: "Please select the quantity",
  payment_information: "Payment Information",
  full_name: "Full Name",
  full_name_placeholder: "Type full name",
  phone_number_placeholder: "Type your phone",
  email_placeholder: "Type Email",
  country: "Country",
  country_placeholder: "Select the country",
  state: "State",
  state_placeholder: "Select the state",
  city: "City",
  city_placeholder: "Select the city",
  address: "Address",
  address_placeholder: "Ex: ABC Building, 1890 NY",
  delivery_to_anathor_address: "Delivery to another address?",
  note: "Note",
  notes_placeholder: "Note about your orders",
  your_order: "Your order",
  payment_method: "Payment Method",
  order: "Order Now",
  promocode_applied_successfully: "The promocode was successfully applied.",
  promocode_total_error:
    "To use this promocode please add some products worth of ##PRODUCT_VALUE##",
  privacy_policy_text:
    "Your personal data will be used to process your order, to support your experience throughout this website, and for other purposes described in the",
  refresh_the_page: "Please refresh the page.",
  any_issues_contact_us: "If you have any issue with our system. Please ",
  do_not_refresh: "PLEASE. DO NOT REFRESH THE PAGE.",
  empty_cart: "Your cart is empty",
  page_not_found: "Page not found.",
  page_not_found_message:
    "We can't seem to find the page you are looking for please go back to previous page or go",
  back_to_home: "Back to Home",
  technical_issue:
    "We have some technical issues in our site. please refresh the page or go",
  personal_info: "Personal Info",
  orders: "Orders",
  change_password: "Change password",
  empty_wishlist: "You wishlist is empty",
  something_went_wrong_details:
    "We have some technical issues in our site. please refresh the page or try again later",
  delete: "Delete",
  gender: "Gender",
  male: "Male",
  female: "Female",
  save: "Save",
  try_again_later: "Something went wrong. please try again later",
  new_password: "New password",
  old_password: "Old password",
  english: "English",
  arabic: "Arabic",
  details: "Details",
  order_number: "Order Number",
  order_status: "Order Status",
  order_date: "Order Date",
  order_total: "Order Total",
  cancel: "Cancel",
  day: "Hour",
  days: "Hours",
  duration: "within",
  order_placed: "Placed",
  order_processing: "Processing",
  order_cancelled: "Cancelled",
  update_cart: "Update cart",
  calcel_order: "Cancel order",
  cancel_reason: "Cancellation reason",
  cancel_order_confirmation:
    "Are you sure you want to cancel order ##ORDERID## ?",
  signout: "Logout",
  tax: "Tax",
  cash_on_delivery: "Cash",
  tabby: "Tabby",
  shop_now: "Shop now,",
  pay_split_tabby: "pay over time.",
  tabby_list1: "No interest. No fees.",
  tabby_list2: "Trusted by 4,5m+ customers.",
  tabby_list3: "Shariah-compliant.",
  tamara_list1: "100% Shariah-compliant.",
  tamara_list2: "credit history may alter payment plan",
  tamara_list3: "Eligible for ages 18+",
  how_tabby_works: "How it works:",
  select_payment_option: "Choose Payment Option",
  pay_full_payment: "Pay full payment",
  pay_by_installments: "Pay by installment",
  select_number_of_installments: "Select Number of Installments",
  step1: "Choose Tabby at checkout",
  step2: "Enter your information and add your debit or credit card",
  step3: "Your first payment is taken when the order is made",
  step4: "We'll send you a reminder when your next payment is due",
  tamara_step1: "Choose Tamara at checkout",
  tamara_step2: "Fill your details",
  tamara_step3: "Complete your 1st payment",

  continue_shooping: "Continue Shopping",
  tabby_description:
    " Split your purchase and pay over time. No fees. No intertest.",
  Learn_more: "Learn more",
  tamara_description: "Split in upto 4 payments or pay your payment in full",
  buy_now: "Buy Now",
  tamara: "Tamara",
  tamara_headline1: "Split in upto 4 interest-free payments,",
  tamara_headline2: "Or pay your order in full",
  online_payment: "Debit/Credit Cards",
  go_to_products: "Go to products",
  pickup_from_store: "Pickup from store",
  continue_as_guest: "Continue as a guest",
  delivery_type: "Delivery type",
  how_do_you_want_to_receive_order:
    "How would you like to receive your order ?",
  product_code: "Product Code",
  area: "Area",
  area_placeholder: "Select the area",
  copied: "Copied to clipboard",
  promotions: "Promotions",
  delete_product: "Product was successfully removed from cart.",
  buy_again: "Buy again",
  download_invoice: "Invoice",
  order_details: "Order Details",
  account_blocked_heading: "Your account has been blocked!",
  account_blocked: " Kindly contact us for more information",
  tabby_success_message: "Your payment as been verified.",
  order_created_successfully: "Your order was created successfully. ",
  Failed_to_create_order: "Payment failed! Please try again",
  tabby_failed_message:
    "Sorry, Tabby is unable to approve this purchase. Please try again or Please use an alternative payment method for your order",
  tamara_failed_message:
    "Sorry, Tamara is unable to approve this purchase. Please try again or Please use an alternative payment method for your order",

  tabby_cancel_message:
    "You aborted the payment. Please retry or choose another payment method.",
  tabby_payment_inprocess: "Payment is not completed, Please try again.",
  tabby_rejected_message:
    "Sorry, Tabby is unable to approve this purchase, please use an alternative payment method for your order.",
  tamara_rejected_message:
    "Sorry, Tamara is unable to approve this purchase, please use an alternative payment method for your order.",
  Order_has_been_delivered: "Order has been delivered",
  Order_out_for_delivery: "Order out for delivery",
  Shipped_at_delivery_center: "Shipped at delivery center",
  Order_is_packed: "Order is packed",
};

export default english;
