let arabic = {
  logo_alt: "الشعار",
  email: "البريد الإلكتروني ",
  password: "كلمة المرور",
  forgot_password: "هل نسيت كلمة المرور ؟ ",
  sign_in_instead: "سجل الآن",
  please_wait: "يرجي الإنتظار",
  something_went_wrong: "هناك خطأ ما.",
  login_success: "لقد تم تسجيلك بنجاح",
  login_to_access: "برجاء تسجيل الدخول",
  terms_of_use: "شروط الإستخدام",
  my_account: "حسابي",
  track_my_order: "متابعة الطلب",
  signin: "تسجيل دخول",
  signup: "انشاء حساب",
  home: "الرئيسية",
  new_arrival: "وصل حديثآ",
  products: "المنتجات",
  product: "منتج",
  about_us: "من نحن",
  contact_us: "تواصل معنا ",
  my_wishlist: "المفضلة ",
  wishlist: "المفضلة  ",
  cart: "السلة ",
  search_products: "ابحث جميع المنتجات",
  naturally_delivered_title: "تسليمها بشكل طبيعي",
  naturally_delivered_description: "منتجات طبيعية عضوية",
  secure_payments_title: "المدفوعات الآمنة",
  secure_payments_description: "محمية بالكامل عند الدفع عبر الإنترنت",
  footer_logo_alt_text: "الشعار",
  facebook: "فيسبوك",
  twitter: "تويتر",
  youtube: "يوتيوب",
  instagram: "انستجرام",
  pinterest: "بينتريست",
  office_info: "معلومات التواصل",
  get_help: "مساعدة",
  faqs: "الأسئلة الشائعة",
  business_with_us: "اعمل معنا",
  shop_links: "تسوق معنا",
  my_cart: "انتقل الى السلة",
  checkout: "إتمام الطلب",
  privacy_policy: "سياسة الخصوصية",
  location_on_map: "موقعنا على الخريطة",
  zipcode: "الرمز البريدي",
  shop_now: "اشتر الآن",
  best_seller_text: "الأفضل مبيعا هذا الإسبوع",
  best_seller_header: "لعسل الخاص بك",
  share: "شارك",
  zoom: "تكبير",
  add_to_cart: "اضف للسلة",
  offers: "العروض",
  best_seller: "كافة المنتجات",

  new_arrival_home: "الأكثر مبيعا",
  offer_poster: "العروض",
  login_page_description: "جاري التحقق من حسابك الشخصي وإعداد الملف الخاص بك",
  sign_in_image_alt_text: "تسجيل الدخول",
  signup_page_description: "جاري التحقق من حسابك الشخصي وإعداد الملف الخاص بك",
  sign_up_image_alt_text: "تسجيل الدخول",
  dont_have_account: "ليس لديك حساب ؟",
  remember_me: "تذكرني",
  first_name: "الإسم الأول ",
  last_name: "الإسم الأخير ",
  confirm_password: "تأكيد كلمة المرور ",
  phone_number: "رقم التلفون ",
  already_have_account: "لدي حساب ",
  create_account: "إنشاء حساب جديد",
  i_agree: "أوافق على جميع الشروط",
  terms_and_policy: "الشروط وسياية الخصوصية",
  check_terms_and_condition: "برجاء الموافقة على الشروط وسياسة الخصوصية",
  password_mismatch: "يجب أن تكون كلمة المرور متطابقة",
  categories: "الأقسام",
  out_of_stock: "نفذت الكمية",
  sort_by: "ترتيب حسب",
  newest: "جديد",
  oldest: "قديم",
  mintomax: "السعر من الأقل إلى الأعلى",
  maxtomin: "السعر من الأعلى إلى الأقل",
  items: "الأصناف",
  all_categories: "جميع الأقسام",
  filter_by_price: "حسب السعر",
  price: "السعر",
  qty: "الكمية",
  total: "المجموع",
  filter: "تصفية",
  clear_filter: "إزالة التصفية",
  show: "عرض",
  filter_by_ratings: "التصفية على التقييم",
  go_back: "الرجوع للصفحة الرئيسية",
  no_data_found: "لا يوجد بيانات",
  products_detail: "تفاصيل المنتج",
  product_details_not_found: "لا يوجد تفاصي للمنتج",
  type: "النوع ",
  incredient: "المكونات",
  reviews: "التقييم",
  in_stock: "متوفر",
  weight: "الوزن",
  reduce: "تقليل",
  increase: "أضف",
  add_to_wishlist: "أضف للمفضلة",
  remove_from_wishlist: "إزالة من المفضلة",
  compare: "قارن",
  delivery: "التوصيل",
  delivery_description: "تسليم سريع وموثوق",
  product_demand:
    "مرغوب من قبل عملاء آخرين ، مضاف الآن في السلة من قبل عملاء آخرين",
  related_products: "منتجات مشابهه",
  description: "الوصف",
  review_for: "التقييم",
  no_reviews_found: "لم يتم العثور على تقييم",
  you: "أنت",
  add_a_review: "اضف تقييم",
  required_field_info: "يجب ملئ الحقل ذات علامة النجمة",
  your_rating: "تقييمي",
  review_title: "عنوان التقييم",
  your_review: "تقييمي",
  submit: "أرسل",
  contact_info: "معلومات التواصل",
  name: "الإسم",
  message: "الرسالة",
  accept_terms_and_condition: "الموافقة على الشروط والأحكام",
  please_accept_terms_and_condition: "برجاء قبول الشروط والأحكام",
  please_login: "لإستخدام هذة الخاصية برجاء تسجيل الدخول",
  remove: "إزالة",
  your_cart: "سلتي",
  sub_total: "المجموع الكلي",
  shipping_fee: "تكلفة الشحن",
  apply_promocode: "أضف الرمز الترويجي",
  apply: "سجل",
  enter_promocode: "أضف الرمز الترويجي",
  payment_process: "تأكيد الطلب",
  continue_shopping: "مواصلة التسوق",
  select_the_quantity: "برجاء إختيار الكمية",
  payment_information: "معلومات الدفع",
  full_name: "الإسم الثلاثي",
  full_name_placeholder: "اكتب الإسم الثلاثي",
  phone_number_placeholder: "اكتب رقم التلفون",
  email_placeholder: "اكتب الإيميل ",
  country: "الدولة",
  country_placeholder: "إختر الدولة",
  state: "ولاية",
  state_placeholder: "إختر ولاية",
  city: "المدينة",
  buy_now: "اشتري الآن",

  city_placeholder: "اختر المدينة",
  address: "العنوان",
  address_placeholder: "Ex: ABC Building, 1890 NY",
  delivery_to_anathor_address: "أوصلها لعنوان آخر",
  note: "ملاحظات ",
  notes_placeholder: "ملاحظان عن الطلب",
  your_order: "طلباتي",
  payment_method: "طريقة الدفع",
  order: "اطلب الآن",
  promocode_applied_successfully: "تم تفعيل الرمز الترويجي",
  promocode_total_error: "لإستخدام هذا الرمز الترويجي أضف منتجات أكثر",
  privacy_policy_text:
    "سيتم استخدام بياناتك الشخصية لمعالجة طلبك، لدعم تجربتك عبر هذا الموقع",
  refresh_the_page: "تحديث الصفحة",
  any_issues_contact_us: "لديك مشكلة مع نظامنا ، برجاء",
  do_not_refresh: "برجاء عدم تحديث هده الصفحة",
  empty_cart: "سلتي فارغة",
  page_not_found: "لم يتم العثور على الصفحة",
  page_not_found_message:
    "لا يمكننا العثور على الصفحة براء الرجوع آلى الصفحة السابقة",
  back_to_home: "الرجوع للصفحة الرئيسة",
  technical_issue: "لدينا مشكلة تقنية برجاء تحديث الصفحة أو اذهب",
  personal_info: "المعلومات الشخصية",
  orders: "الطلبات",
  change_password: "تغيير كلمة المرور",
  empty_wishlist: "مفضلتي فارغة",
  something_went_wrong_details:
    "لدينا مشكلة تقنية برجاء تحديث الصفحة أو المحاولة لاحقا",
  delete: "مسح",
  gender: "الجنس",
  male: "ذكر",
  female: "انثى",
  save: "حفظ",
  try_again_later: "هناك خطأ حاول مرة أخرى",
  new_password: "كلمة المرور جديدة",
  old_password: "كلمة المرور قديمة",
  english: "English",
  arabic: "اللغة العربية",
  details: "التفاصيل",
  order_number: "رقم الطلب",
  order_status: "حالة الطلب",
  order_date: "تاريخ الطلب",
  order_total: "مجموع عدد الطلبات",
  cancel: "إلغاء",
  day: "ساعة",
  days: " ساعة",
  duration: "خلال",
  order_placed: "تم إنشاء طلبك",
  order_processing: "جاري العمل عليه",
  order_cancelled: "ملغي",
  update_cart: "تحديث السلة",
  cancel_reason: "سبب الإلغاء",
  calcel_order: "إلغاء الطلب",
  cancel_order_confirmation: "هل أنت متأكد من إلغاء طلبك ؟",
  signout: "تسجيل خروج",
  tax: "ضريبة",
  cash_on_delivery: "نقد",
  online_payment: "بطاقات الخصم/الائتمان",
  tabby: "تابي",
  tamara: "تمارا",
  shop_now: "تسوق الحين،",
  pay_split_tabby: " وقسّمها على فترة أطول",
  tabby_list1: "بدون أي فوائد، أو رسوم.",
  tabby_list2: "موثوق لدى أكثر من 4.5 ملايين عميل.",
  tabby_list3: "متوافقة مع الشريعة الإسلامية.",
  how_tabby_works: "آلية الاستخدام:",
  step1: "اختر تابي عند الدفع",
  step2: "أدخل بياناتك و بيانات البطاقة البنكية أو البطاقة الائتمانية",
  step3: "يتم سداد الدفعة الأولى عند إتمام الطلب",
  step4: "سنرسل لك رسائل لتذكيرك بموعد استحقاق دفعتك القادمة",
  continue_shooping: "تابع التسوق",
  go_to_products: "اذهب إلى المنتجات",
  pickup_from_store: "الإستلام من المحل",
  continue_as_guest: "استمر كضيف",
  delivery_type: "نوع التوصيل",
  how_do_you_want_to_receive_order: "كيف تود إستلام طلبك",
  product_code: "كود المنتج",
  area: "المنطقة",
  area_placeholder: "اختر المنطقة",
  copied: "تم نسخها إلى الحافظة",
  promotions: "العروض",
  delete_product: "تمت إزالة المنتج من سلة التسوق بنجاح",
  buy_again: "شراء مرة أخرى",
  download_invoice: "فاتورة",
  confirmed: " مؤكد",
  order_details: "تفاصيل الطلب",
  account_blocked_heading: "تم حظر حسابك!",
  account_blocked: "يرجى الاتصال بنا للحصول على مزيد من المعلومات",
  tabby_success_message: "لقد تم التحقق من الدفع الخاص بك.",
  order_created_successfully: "تم إنشاء طلبك بنجاح. ",
  Failed_to_create_order: "عملية الدفع فشلت! حاول مرة اخرى",
  tabby_failed_message:
    "نأسف، تابي غير قادرة  على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى",
  tamara_failed_message:
    "عذرًا، تمارا غير قادر على الموافقة على هذه المعاملة. الرجاء استخدام طريقة دفع أخرى.",
  tabby_cancel_message:
    "لقد ألغيت الدفعة. فضلاً حاول مجددًا أو اختر طريقة دفع أخرى.",
  tabby_payment_inprocess: "عملية الدفع فشلت! حاول مرة اخرى",
  tabby_rejected_message:
    "نأسف، تابي غير قادرة  على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى",
  tamara_rejected_message:
    "نأسف، تمارا غير قادرة  على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى",

  Order_has_been_delivered: "تم تسليم الطلب",
  Order_out_for_delivery: "اطلب الخروج للتسليم",
  Shipped_at_delivery_center: "يتم شحنها في مركز التسليم",
  Order_is_packed: "النظام معبأة",
  tabby_description: "قسّم مشترياتك وادفعها على كيفك. بدون أي فوائد، أو رسوم ",
  tamara_description: "تقسيط على 4 دفعات أو دفع المبلغ بالكامل",
  Learn_more: "لمعرفة المزيد",
  tamara_step1: "اختر تمارا عند الدفع",
  tamara_step2: "املأ تفاصيلك",
  tamara_step3: "أكمل دفعتك الأولى",
  tamara_list1: "100% متوافقة مع الشريعة الإسلامية",
  tamara_list2: "قسم فاتورتك حتى 4 دفعات",
  tamara_list3: "مؤهلة للأعمار 18+",
  tamara_headline1: "قسم فاتورتك أو",
  tamara_headline2: "ادفع كامل قيمة طلبك",
};

export default arabic;
